import { useEffect, useState } from 'react';
import { debounce } from 'lodash';
import { isDesktop, isMobile } from '@styles/breakpoints';

export const useResize = () => {
  const [isClient, setIsClient] = useState(false);
  const [windowWidth, setWindowWidth] = useState<number | undefined>(!isClient ? undefined : window.innerWidth);
  const [handlerCreated, setHandlerCreated] = useState(false);
  const isDesktopView = isDesktop(windowWidth);
  const isMobileView = isMobile(windowWidth);

  useEffect(() => {
    setIsClient(true);
  }, []);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }
    if (isClient && !handlerCreated) {
      handleResize();
      const debouncedResize = debounce(handleResize, 200);
      window.addEventListener('resize', debouncedResize, false);
      setHandlerCreated(true);
      return () => {
        setHandlerCreated(false);
        window.removeEventListener('resize', debouncedResize, false);
      };
    }
  }, [isClient]);
  return [isDesktopView, isMobileView];
};
